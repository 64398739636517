.free-results-container {
  position: relative;
  width: 100%;
}

.download-button-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.download-pdf-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #0056b3;
  }

  &:active {
    background-color: #004085;
  }
}

// Hide elements with no-print class in print view
@media print {
  .no-print {
    display: none !important;
  }
  .logo-wrapper img {
    width: 60%
  }
} 