// General styles
.header2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
}

.margin-top {
  margin-top: 20px;
}

.pb-10 {
  padding-bottom: 10px;
}

.break_before--page {
  page-break-before: always;
}

.break_inside--avoid_page {
  page-break-inside: avoid;
}

.m-0 {
  margin: 0;
}

.flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.inline-block {
  display: inline-block;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
}

.flex-item {
  flex: 1;

  &--shrink {
    flex-shrink: 1;
    flex-grow: 0;
  }
}

// Symptom Survey specific styles
.diagnostic_test_body {
  margin-bottom: 30px;

  .survey-test-top-content {
    margin-bottom: 20px;
  }
}

// General info box styles
.test_general-info_box {
  background-color: #f5f5f5;
  padding: 15px;
  border-radius: 4px;
  margin-bottom: 10px;

  .common-title {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 5px;
  }

  .info-body {
    font-size: 14px;
  }
}

// Preliminary results styles
.preliminary-results_box {
  margin-bottom: 20px;

  .course-сhart_wrapper {
    max-width: 120px;

    .courseChart {
      max-width: 100%;
      height: auto;
    }
  }

  .commot-list {
    list-style-type: none;
    padding-left: 0;

    li {
      margin-bottom: 5px;
    }
  }
}

// Dementia duration box styles
.dementia-duration_box {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  width: 100%;
  max-width: 450px;

  .dementia_sections-list {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0 0 5px 0;
    height: 16px;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid #E3E3E3;
    li {
      flex: 1;
      height: 100%;
      border-right: 2px solid black; /* Black dividers */
      
      &:last-child {
        border-right: none;
      }

      &.active {
        background-color: #e45a51; /* Keep the same color for active items */
      }
    }
  }

  .dementia_values-list {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    justify-content: space-between;
    font-size: 14px;
    width: 100%;

    li {
      flex: 1;
      text-align: center;
    }
  }
}

// Brain map styles
.disfunctions_analize {
  margin-bottom: 50px;
  width: 100%;

  .brains-analize-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;

    .brain_map_box {
      width: 48%;
      position: relative;
      margin: 0 10px;

      .brain_layer {
        position: relative;
        margin: 0 auto;

        &--profil {
          width: 100%;
          max-width: 550px; // Increased from 406.41px to make brain bigger
          height: auto;
          aspect-ratio: 406.41 / 250.81; // Keep aspect ratio
          
          .BrainProfil {
            width: 100%;
            height: auto;
            display: block;
          }

          .additional-wrapper {
            position: relative;
            width: 100%;
            height: 100%;
          }
        }

        &--top {
          width: 100%;
          max-width: 550px; // Match profile size
          height: auto;
          aspect-ratio: 1.4 / 1; // More oval shape for cross-section
          position: relative;
          overflow: visible;
          
          svg {
            width: 100%;
            height: auto;
            display: block;
          }
        }
      }
    }
  }
}

// Brain positioning calculations
$containerWidth: 406.41;
$containerHeight: 250.81;

@function getPercents($val, $isHorizont) {
  @if $isHorizont {
    @return ((100 * $val) / $containerWidth) * 1%
  } @else {
    @return ((100 * $val) / $containerHeight) *1%
  }  
};

@function getSKPercents($val) {
  @return ((100 * $val) / 325.55) * 1% 
};

// Brain points styles
.brain_points-list {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .brain_point-item {
    position: absolute;
    height: 35px;
    width: 35px;
    flex: 0 0 35px;
    border: 2px solid #fff;
    border-radius: 50%;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    transition-duration: 1s;
    cursor: pointer;

    &:after {
      content: attr(data-content);
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate3D(-50%, -115%, 0);
      min-width: 100px;
      padding: 5px;
      color: #fff;
      background: #000;
      font-size: 12px;
      font-weight: 600;
      display: none;
      text-align: center;
      z-index: 10;
    }
    &:hover {
      height: 45px;
      width: 45px;
      box-shadow: 0px 1px 20px rgba(255, 255, 255, 0.6);
      transition-duration: 0.5s;
      z-index:1;
      &:after { display: block; }
    }
    > span {
      font-size: 13px;
      font-weight: 600;
      line-height: 1;
      color: #000;
    }

    // F positions (Frontal)
    &.f0 {
      left: getPercents(155, true);
      top: getPercents(21, false);
    }
    &.f1 {
      left: getPercents(110, true);
      top: getPercents(35, false);    
    }
    &.f2 {
      left: getPercents(68, true);
      top: getPercents(58, false);
    }
    &.f3 {
      left: getPercents(143, true);
      top: getPercents(55, false);
    }
    &.f4 {
      left: getPercents(105, true);
      top: getPercents(67, false);
    }
    &.f5 {
      left: getPercents(158, true);
      top: getPercents(110, false);
    }
    &.f6 {
      left: getPercents(15, true);
      top: getPercents(108, false);
    }
    &.f7 {
      left: getPercents(30, true);
      top: getPercents(132, false);
    }
    &.f8 {
      left: getPercents(62, true);
      top: getPercents(132, false);
    }
    &.f9 {
      left: getPercents(100, true);
      top: getPercents(134, false);
    }

    // PT positions (Parietal/Temporal)
    &.pt0 {
      left: getPercents(288, true);
      top: getPercents(26, false);
    }
    &.pt1 {
      left: getPercents(313, true);
      top: getPercents(45, false);
    }
    &.pt2 {
      left: getPercents(334, true);
      top: getPercents(72, false);
    }
    &.pt3 {
      left: getPercents(350, true);
      top: getPercents(105, false);
    }
    &.pt4 {
      left: getPercents(236, true);
      top: getPercents(70, false);
    }
    &.pt5 {
      left: getPercents(268, true);
      top: getPercents(110, false);
    }
    &.pt6 {
      left: getPercents(300, true);
      top: getPercents(147, false);
    }
    &.pt7 {
      left: getPercents(215, true);
      top: getPercents(145, false);
    }
    &.pt8 {
      left: getPercents(225, true);
      top: getPercents(179, false);
    }
    &.pt9 {
      left: getPercents(151, true);
      top: getPercents(206, false);
    }
  }
}

// SK positions (Subcortical)
@for $i from 0 through 9 {
  .brain_points-list .brain_point-item.sk#{$i} {
    // Calculate position based on percentage across the width
    // This ensures even spacing between 5% and 95% of the width
    left: 8% + ($i * 8%);
    top: 50%;  // Center vertically
    transform: translateY(-50%);  // Ensure perfect vertical centering
  }
}

// Color classes for brain indicators
.red {
  background-color: #FA5001; // Orange-red color from reference
  color: #000;
  border: 1px solid #d84400;
}

.yellow {
  background-color: #FFE600; // Bright yellow color from reference
  color: #000;
  border: 1px solid #d9c300;
}

.green {
  background-color: #92DE50; // Light green color from reference
  color: #000;
  border: 1px solid #7dc540;
}

.white {
  background-color: #fff;
  border: 1px solid #dddddd;
  color: #000;
}

// Brain graph analyze
.brain_graph_analize {
  margin-bottom: 20px;

  .survey-diagnostic-header {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 10px;

    span {
      flex: 1;
      text-align: center;
      padding: 0 5px;
    }
  }

  .diagnostic_question_body {
    .survey-diagnostic-wrapper {
      display: flex;
      width: 100%;
      gap: 10px;

      .survey-diagnostic-list {
        flex: 1;
        list-style: none;
        padding: 0 5px;
        margin: 0;

        li {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: 10px;
          gap: 5px;

          .brain-zone_color {
            padding: 8px;
            font-size: 12px;
            min-width: 25px;
            height: 25px;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #000;
            flex-shrink: 0;
          }

          .brain-zone_title {
            font-size: 12px;
            text-align: left;
            margin: 0;
            flex: 1;
          }
        }
      }
    }
  }
}

// Legend styles
.disfunctions_legend {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 20px;

  .legend-item_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .legend-item {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 25px;
      height: 25px;
      border-radius: 4px;
      margin-right: 10px;
      span {
        font-size: 12px;
        white-space: nowrap;
      }
    }
  }
}

// Question Answer styles
.question-answer-wrapper {
  margin-bottom: 20px;

  h3 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .answers-wrapper {
    padding-left: 20px;

    .answer-item {
      margin-bottom: 10px;

      dt {
        font-size: 14px;
        margin-bottom: 5px;
      }
    }
  }
}

// Progress Line styles
.risk_progress-legend {
  margin-bottom: 10px;
  position: relative;

  .info-icon {
    margin-left: 5px;
    cursor: pointer;

    &.no-print {
      display: none;
      @media print {
        display: none;
      }
    }
  }

  .info_list {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background: #fff;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 10;

    &:hover, .info-icon:hover + & {
      display: block;
    }
  }
}

.card-header-duration_box {
  .risk_progress-chart {
    position: relative;
    height: 14px;
    background-color: #f5f5f5;
    border-radius: 8px;

    .progress-line {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      border-radius: 8px;

      &.progress-line-family {
        background: linear-gradient(90deg, #529e00 0%, #ffee90 30%, #ffee90 70%, #BA1717 100%);
        width: 100%;
        height: 100%;
      }
    }
  }

  .risk_progress-pointer {
    position: relative;
    height: 25px;

    .pointer {
      position: absolute;
      bottom: 0;
      transform: translateX(-50%);
      width: 20px;
      height: 20px;
      transition: left 0.3s ease;
    }
  }
}

// Small footnote styles
p[style*="font-size: 9px"] {
  margin-top: 30px;
  color: #666;

  a {
    color: #0066cc;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

// Print styles
@media print {
  @page {
    margin: 0;
    size: auto;
  }

  html, body {
    margin: 0;
  }

  .diagnostic_test_body {
    display: block !important;
    margin: 0;
    padding: 0;
  }

  .dementia-duration_box {
    display: flex !important;
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
    
    .dementia_sections-list {
      display: flex !important;
      
      li {
        print-color-adjust: exact;
        -webkit-print-color-adjust: exact;
        
        &.active {
          background-color: #e45a51 !important;
        }
      }
    }
  }

  .risk_progress-chart {
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
    
    .progress-line-family {
      print-color-adjust: exact;
      -webkit-print-color-adjust: exact;
    }
  }

  .brain_points-list .brain_point-item {
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
    box-shadow: none !important;

    &:after {
      display: none !important;
    }

    &:hover {
      box-shadow: none !important;
    }
  }

  .info_list {
    display: none !important;
  }

  .info-icon {
    display: none !important;
  }

  .brain_graph_analize,
  .disfunctions_legend,
  .brain_map_box,
  .brains-analize-wrapper {
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }

  // Ensure colors print correctly
  .red, .yellow, .green, .white {
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }

  // Hide all questions by default in print view
  .question-answer-wrapper {
    display: none !important;
  }

  // Show only specific questions in print view
  .question-answer-wrapper[data-slug="symptoms-variations-day-to-day"],
  .question-answer-wrapper[data-slug="first-symptoms-noticed"],
  .question-answer-wrapper[data-slug="most-noticeable-symptoms"],
  .question-answer-wrapper[data-slug="hard-to-manage-symptoms"],
  .question-answer-wrapper[data-slug="evident-specific-event-symptoms"],
  .question-answer-wrapper[data-slug="stress-for-the-family"],
  .question-answer-wrapper[data-slug="patienten-agree-with-symptom-survey"],
  .question-answer-wrapper[data-slug="space-for-comments"] {
    display: block !important;
  }

  // Footer print styles
  .footer {
    padding-top: 20px !important;
    
    .flex--wrap {
      flex-direction: column !important;

      .w-100 {
        display: flex !important;
        flex-direction: column !important;
        gap: 15px !important;

        // Container for bottom row items
        > div:nth-child(2) {
          display: flex !important;
          flex-direction: row !important;
          justify-content: space-between !important;
          align-items: flex-start !important;
          flex-wrap: nowrap !important;
        }
      }
    }

    .footer-item {
      padding-top: 0 !important;

      &:first-child {
        margin-bottom: 10px !important;
        border-bottom: 1px solid #eee !important;
        padding-bottom: 10px !important;
        display: flex !important;
        justify-content: center !important;
      }

      .footer-legal-mark {
        align-items: center !important;
        flex-direction: row !important;
        gap: 10px !important;

        p {
          margin: 0 !important;
          font-size: 8px !important;
          white-space: nowrap !important;
          line-height: 1.2 !important;
        }

        img {
          height: 20px !important;
        }

        span {
          font-size: 8px !important;
          white-space: nowrap !important;
          line-height: 1.2 !important;
        }

        // Specific styling for version info
        div {
          p {
            line-height: 1.2 !important;
            margin: 0 !important;
          }
        }
      }
    }

    // Bottom row items
    .footer-item:not(:first-child) {
      flex: 1 !important;
      display: flex !important;
      justify-content: flex-start !important;
      align-items: flex-start !important;
      padding: 0 10px !important;
    }
  }
}