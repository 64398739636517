.login_container {
  display: flex;
  height: 100%;
}

.login_wrapper {
  align-self: flex-start;
}

.login_title {
  padding: 50px 0;
  text-align: center;
  font-size: 20px;
}

.login_card-box {
  background: #ffffff;
  border-top: 3px solid #71aa33;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.2);
  padding: 16px;
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 500px) {
    width: 565px
  }
}

.login_form-holder {
  position: relative;
  min-height: 260px;
  .login_lang-switcher {
    position: absolute;
    right: 0;
    top: 0;
  }
}

.input-holder {
  select,
  textarea,
  input[type="text"],
  input[type="password"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="date"],
  input[type="month"],
  input[type="time"],
  input[type="week"],
  input[type="number"],
  input[type="email"],
  input[type="tel"],
  input[type="url"] {
    font-size: 16px;
  }
}

.input-holder {
  position: relative;
  label {
    color: $text-color_primary;
    display: block;
    line-height: 16px;
  }
  input {
    color: $text-color_primary;
    border: 1px solid $ordinary-grey;
    border-radius: 4px;
    padding: 8px 6px;
    width: 100%;
    background-color: white;
    -webkit-appearance: none;
  }
  span {
    font-size: 10px;
    position: absolute;
    left: 0;
    bottom: -15px;
    font-weight: 600;
  }
  &.field_error {
    input {
      border-color: $error-color;
    }
    span {
      color: $error-color;
    }
  }
}

.input {
  color: $text-color_primary;
  border: 1px solid $ordinary-grey;
  border-radius: 4px;
  width: 100%;
}

.login_btns-holder button {
  button {
    border-radius: 4px;
    font-size: 14px;
    padding-left: 10px;
    padding-right: 10px;
    width: auto;
    background: #71aa33;
    color: #ffffff;
    height: 36px;
    border: none;
    box-shadow: none;
    outline: none;
    cursor: pointer;
    font-weight: 600;
  }
  a {
    text-decoration: none;
    font-size: 12px;
    color: #424242;
  }
}

.login_switcher-holder {
  display: flex;
  align-items: center;
  text-align: center;
  margin-bottom: 24px;
}

.login_switcher-btns {
  display: flex;
  flex:  1 1 auto;
  span {
    width: 70px;
    border-radius: 4px;
    border: 1px solid #71aa33;
    padding: 2px;
    margin-left: 10px;
    color: #6c757d;
    cursor: pointer;
    &.active {
      background-color: #71aa33;
      color: #ffffff;
    }
  }
}

.min-height-wrapper {
  min-height: 170px;
}


.login-title {
  font-size: 16px;
  font-weight: 600;
}
